import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  podDetails: any;

  constructor(private _httpClient: HttpClient, public afAuth: AngularFireAuth) { }

  //1. 1st call to Fetch normal token via request id
  fetchCustomToken(reuqestId, email) {
    var url = environment.signInApiUrl;
    return this._httpClient.post(url, {
      "token": reuqestId,
      "eid": email
    }).toPromise();
  }

  //2. 2nd call to use above normal token to fetch idToken
  async authenticateUser(customeToken) {
    return this.afAuth.auth.signInWithCustomToken(customeToken)
  }

  //get the refresh token 
  refreshToken() {
    return this.afAuth.auth.currentUser
  }

  signout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem("idToken");
  }

  fetchPodDetails(reuqestId) {
    var url = `${environment.areasFetchApiUrl}${reuqestId}`
    let accessToken = window.localStorage.getItem('idToken');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' , 'Authorization': `Bearer ${accessToken}`});
    return this._httpClient.get(url, { headers: headers }).toPromise();
  }

  setPodDetails(data) {
    if (!this.podDetails) {
      this.podDetails = data;
    } else {
      this.podDetails.errorCode = data.errorCode;
    }
  }

  getPodDetails() {
    return this.podDetails;
  }

  submitFeedback(payload, webRefId) {
    var url = `${environment.submitFeedbackApiUrl}${webRefId}`;
    let accessToken = window.localStorage.getItem('idToken');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` });
    return this._httpClient.post(url, payload, { headers: headers }).toPromise();
  }

  updateAcceptanceDateOfTermsAndPrivacy(eid, webRefId) {
    var url = environment.acceptanceTermsAndPpUrl;
    let payload = { 'eid': eid, 'token': webRefId }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._httpClient.post(url, payload, { headers: headers }).toPromise();
  }

  updateTermsAndPrivcy(type, version, contactId) {
    var url = `${environment.updateTermsAndPpUrl}${contactId}?action=privacy-tou`;
    let payload = { 'type': type, 'version': version }
    let accessToken = window.localStorage.getItem('idToken');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` });
    return this._httpClient.put(url, payload, { headers: headers }).toPromise();
  }

  setCookies(cookieToken) {
    var url = `${environment.setCookieApiUrl}`;
    let payload = { 'cookieToken': cookieToken }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this._httpClient.post(url, payload, { headers: headers }).toPromise();
  }

  fetchQuestionDetails(reuqestId) {
    var url = `${environment.questionsFetchApiURL}`
    let accessToken = window.localStorage.getItem('idToken');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' , 'Authorization': `Bearer ${accessToken}`});
    return this._httpClient.get(url, { headers: headers }).toPromise();
  }

}
