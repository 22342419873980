import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.css']
})
export class SendFeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  openTermsWindow(type) {
    if (type === 'TERMS')
      window.open(environment.termsPageUrl);
    else if (type === 'COOKIE')
      window.open(environment.cookiePolicyPageUrl);
    else
      window.open(environment.privacyPolicyPageUrl);
  }

}
