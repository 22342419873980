// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'qa',
  firebaseConfig: {
    apiKey: "AIzaSyCEbca85KZrq6wVo3Lsi4tI5GVwp2vbhbg",
    authDomain: "companion-qa.firebaseapp.com",
    databaseURL: "https://companion-qa.firebaseio.com",
    projectId: "companion-qa",
    storageBucket: "companion-qa.appspot.com",
    messagingSenderId: "632050046416",
    appId: "1:632050046416:web:b46286b226e6d4339115d5",
    measurementId: "G-5XD9CR6CS2"
  },
  signInApiUrl: "https://qa.pods.globant.com/auth/signin?action=init",
  areasFetchApiUrl: "https://qa.pods.globant.com/pods?privacy-tou=true&webRefId=",
  submitFeedbackApiUrl: "https://qa.pods.globant.com/feedback/pod-feedback?webRefId=",
  acceptanceTermsAndPpUrl: "https://qa.pods.globant.com/auth/signin?action=privacy-tou-updates",
  termsPageUrl: "https://us-central1-companion-qa.cloudfunctions.net/legal_v2_0_0/terms-of-use",
  privacyPolicyPageUrl: "https://us-central1-companion-qa.cloudfunctions.net/legal_v2_0_0/privacy-policy",
  updateTermsAndPpUrl: "https://us-central1-companion-qa.cloudfunctions.net/api_qa_v5_1_0/clients/",
  setCookieApiUrl: "https://qa.pods.globant.com/auth/cookie",
  cookiePolicyPageUrl: "https://firebasestorage.googleapis.com/v0/b/companion-qa.appspot.com/o/privacy_tou%2Fcookie-policy.html?alt=media",
  questionsFetchApiURL: "https://qa.pods.globant.com/feedback/questions"
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
