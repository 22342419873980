<div>
    <div class="wrapper"
        [ngClass]="{'adjust-wrapper-height' : openAdvertisment || openTermsDialog || openUpdateTermsDialog}"
        [style.height.px]="myInnerHeight">
        <div class="green-shape">
            <img class="green-shape-image" src="../assets/images/Green shape.png" alt="globant_pod_logo" />
            <div class="pod-logo" [ngClass] = "{'addBlur': openTermsDialog || openUpdateTermsDialog}">
                <img src="../../assets/images/Logo@2x.png" alt="globant_pod_logo" />
            </div>
            
        </div>
        <div class="globant-logo desktop" [ngClass] = "{'addBlur': openTermsDialog || openUpdateTermsDialog}">
            <img height="22px" width="109px" src="../../assets/images/globant-logo-dark@2x.png"
                alt="globant_dark_logo" />
        </div>
        <!-- <div class="advertisment" [ngClass]="{'add_blur':openAdvertisment}">
            <div class="add_wrapper" [ngStyle]="{'visibility':initialLoading ? 'visible' : 'hidden' }"
                [ngClass]="{'add_wrapper_collapsed':!openAdvertisment ,'add_wrapper_expand':openAdvertisment}"
                (click)="expandAdd()" (animationend)="animEnd($event)">
                <div class="close-btn-collapsed" [ngClass]="{'expand_close_btn':!openAdvertisment}">
                    <div [ngStyle]="{'display':!openAdvertisment ? 'none' : '' }">
                        <span style="font-size: 25px; font-weight: 500;">X</span>
                    </div>
                    <div class="download_btn" [ngStyle]="{'display':openAdvertisment ? 'none' : '' }">
                        <span>App</span>
                        <span>GlobantPODs</span>
                        <span>Download</span>
                        <span>
                            <div class="square"></div>
                        </span>
                    </div>
                </div>
                <div class="adv-container">
                    <div class="tilted-images">
                        <img src="../../assets/space_theme/phones/phones@2x.png" alt="facebook" />
                    </div>
                    <div class="adv-globantPods-logo">
                        <img src="../../assets/space_theme/Globant PODs Logo/Globant PODs Logo@2x.png" alt="globant pods logo"/>
                        <p class="sub-logo"> By <img class="by-globant-logo" src="../../assets/space_theme/globant-logo-dark/globant-logo-dark@2x.png" alt="globant dark logo" />
                    </div>
                    <div class="kudos-text">
                        <p> Send Kudos and Feedback to your PODs at<br>
                            any time. Access your feedback history and <br>
                            more in-depth knowledge of your PODs and <br>
                            members.</p>
                    </div>
                    <div class="download-links">
                        <a href="https://play.google.com/store/apps/details?id=com.globant.companionapp&hl=en_IN"
                            target="_blank"><img class="google-app-store" src="../../assets/space_theme/Download_app/Download_app@2x.png"
                                alt="android" /></a>
                        <a href="https://apps.apple.com/mr/app/globantpods/id1471234255" target="_blank"> <img
                                src="../../assets/space_theme/Download_ios/Download_ios@2x.png" alt="ios" /></a>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="content" #contentRef [ngStyle]="{'display':!isError ? 'block' : 'none' }" *ngIf="!isThankYouPage">
            <div class="loader" *ngIf="isLoading">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div class="inner-content" *ngIf="!isLoading">
                
                
                <div class="innner-content-left">
                    <div class="pod-details">
                        <div class="pod-name">
                            <p>Let's give feedback to</p>
                            <p>{{podName}}</p>
                            <p>{{projectName}}</p>
                        </div>
                        <!-- <div class="faded-rings">
                            <img class="faded-rings-desktop" src="../../assets/images/userwaves@2x.png" alt="lets work" />
                            <img class="faded-rings-mobile" src="../../assets/space_theme/faded_rings_mobile/Pic@2x.png" alt="lets work" />
                        </div> -->
                        <div class="animal-symbol">
                            <img height="106px" width="106px" style="border-radius: 50%;" [src]="podLogo"
                                alt="animal-symbol" />
                        </div>
                    </div>
                    <div class="feedback-form">
                        <div class="feedback-form-content">
                            <div class="feedback-label">Feedback</div>
                            <ng-container *ngFor="let question of feedbackQuestions['questions']">
                                <div class="feedback-question-container">
                                <div class="qestion-top">
                                    <span class="question-no pull-left">{{question['questionId']}}.</span>
                                    <div class="question">{{question['question']}}</div>
                                </div>
                                <div class="options">
                                    <ng-container *ngFor="let option of question.options">
                                        <div class="option" [ngClass] = "{'active': option.selected}" (click)="optionSelected(question.questionId, option)">
                                            <div class="smileyImg {{optionImages[option.optionValue]}}"></div>
                                            <span>{{option['optionValue']}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                                </div>
                            </ng-container>
                        </div>
                        
                    </div>
                    
                </div>
                
                <div class="innner-content-right">
                    <div class="comments" [ngClass]="{'disabled-background':expansion}">
                        <p>Any Comments?</p>
                        <textarea [disabled]="expansion" [(ngModel)]="comments" maxlength="3000"></textarea>
                    </div>
                </div>
            </div>
            <div class="submit" *ngIf="!isLoading">
                <!-- <hr class="desktop"> -->
                <div [ngClass]="{'submit-enabled': !disableSummitBtn, 'submit-disabled': disableSummitBtn}"
                    (click)="!disableSummitBtn && sumbitFeedback()">
                    <!-- <img *ngIf="!disableSummitBtn" src="../../assets/images/Group 5452.png" alt="group" />
                    <img *ngIf="disableSummitBtn" src="../../assets/images/group.png" alt="group" /> -->
                    <span class="submit-text">Send Feedback</span>
                </div>
            </div>
        </div>
        <div class="thanks-content" #thankscontentRef [ngStyle]="{'display':!isError ? 'block' : 'none' }" *ngIf="isThankYouPage">
            <div class="pod-details">
                <div class="faded-rings">
                    <img class="faded-rings-desktop" src="../../assets/space_theme/userwaves/userwaves@2x.png" alt="lets work" />
                    <img class="faded-rings-mobile" src="../../assets/space_theme/faded_rings_mobile/Pic@2x.png" alt="faded_rings" />
                </div>
                <div class="animal-symbol">
                    <img height="106px" width="106px" [src]="podLogo" alt="animal-symbol" />
                </div>
                <div class="pod-name">
                    <p>{{podName}}</p>
                    <p>{{projectName}}</p>
                </div>
            </div>
            <div class="thanks-note">
                <div>
                    <p *ngIf="feedbackStatus" class="thankyou-text">Thank You!</p>
                    <p *ngIf="!feedbackStatus" class="hmm-text">Humm… I see!</p>
                </div>
                <div class="thankyou-subtext">
                    <p *ngIf="feedbackStatus">Your Feedback has been sent to <span class="font-bold">{{podName}}</span></p>
                    <p *ngIf="!feedbackStatus">Seems like you <span class="font-bold">already answered</span> this feedback request,<span class="font-bold">Thank You.</span></p>
                </div>
            </div>
        </div>
        <div class="error-container" [ngStyle]="{'display':isError ? 'flex' : 'none' }">
            <div class="error-img">
                <img [src]="errorImg" alt="erro_something_went_wrong" />
            </div>
            <div class="error-msg">
                <p>{{errorMsgHeading}}</p>
            </div>
            <div class="error-description">
                <p>{{errorMsg}}</p>
            </div>
            <div class="cta" (click)="tryAgain()"
                [ngStyle]="{'display':(errorCode === 500|| errorCode === 4045||errorCode === 4015||errorCode ===4014 || errorCode === 4046 || errorCode === 4018) ? 'none' : 'block' }">
                <span>LET'S TRY AGAIN</span>
            </div>
        </div>
        <div class="terms-container" *ngIf="openTermsDialog">
            <div class="terms-dialog">
                <div class="pod-symbol">
                    <img src="../../assets/space_theme/Globant PODs Logo/Globant PODs Logo@2x.png" alt="globant_pod_logo"/>
                </div>
                <div class="pod-diff-logo">
                    <p class="sub-logo"> By <img class="by-globant-logo" src="../../assets/space_theme/globant-logo-dark/globant-logo-dark@2x.png" alt="globant dark logo" />
                </div>

                <div class="user-details">
                    <p>Hello</p>
                    <p>{{emailId}}</p>
                </div>
                <div class="terms-acceptance-area">
                    <p>
                        You must agree to the <a (click)="openTermsWindow('TERMS OF USE')">TERMS OF USE</a> and
                        <a (click)="openTermsWindow('privacy policy')">PRIVACY POLICY</a> in order to continue using
                        GlobantPODs app..
                    </p>
                    <p>
                        Please read it carefully.
                    </p>
                </div>
                <div class="terms-cta">
                    <div (click)="acceptTermsAndCondition()">I Agree</div>
                </div>
            </div>
        </div>
        <div class="terms-container" *ngIf="openUpdateTermsDialog">
            <div class="updated-terms-dialog">
                <div class="pod-symbol">
                    <img src="../../assets/space_theme/Globant PODs Logo/Globant PODs Logo@2x.png" alt="globant_pod_logo"/>
                </div>
                <div class="pod-diff-logo">
                    <p class="sub-logo"> By <img class="by-globant-logo" src="../../assets/space_theme/globant-logo-dark/globant-logo-dark@2x.png" alt="globant dark logo" />
                </div>
                <div class="user-details">
                    <p>Hello</p>
                    <p>{{emailId}}</p>
                </div>
                <div class="terms-acceptance-area">
                    <p>
                        We've updated our <a (click)="openTermsWindow(policyOption)">{{policyOption}}.</a> Please read
                        it
                        carefully.
                    </p>
                    <p>You must agree to the updated Terms in order to continue. </p>
                    <p class="effective-date"> <span>{{policyOptionText}} Update</span> <span>Effective on
                            {{effectiveDate}}</span></p>
                </div>
                <div class="updated-terms-cta">
                    <div (click)="updateTermsAndCondition()">I Agree</div>
                </div>
            </div>
        </div>
        <!-- <div class="terms-container-mobile" *ngIf="openTermsDialog">
            <div class="terms-dialog-mobile">
                <div class="globant-white-logo">
                    <img width="96px" height="19px" src="../../assets/images/globant-logo-white@2x.png">
                </div>
                <div class="pod-symbol-mobile">
                </div>
                <div class="pod-diff-logo">
                    <img width="228px" height="55px" src="../../assets/images/Group 8983@2x.png">
                </div>

                <div class="user-details">
                    <p>Hello</p>
                    <p>{{emailId}}</p>
                </div>
                <div class="terms-acceptance-area">
                    <p>
                        You must agree to the <a (click)="openTermsWindow('TERMS OF USE')">TERMS OF USE</a> and
                        <a (click)="openTermsWindow('privacy policy')">PRIVACY POLICY</a> in order to continue using
                        GlobantPODs app..
                    </p>
                    <p>
                        Please read it carefully.
                    </p>
                </div>
                <div class="terms-cta">
                    <div (click)="acceptTermsAndCondition()">I Agree</div>
                </div>
            </div>
        </div>
        <div class="terms-container-mobile" *ngIf="openUpdateTermsDialog">
            <div class="terms-dialog-mobile">
                <div class="globant-white-logo">
                    <img width="96px" height="19px" src="../../assets/images/globant-logo-white@2x.png">
                </div>
                <div class="pod-symbol-mobile">
                </div>
                <div class="pod-diff-logo">
                    <img width="228px" height="55px" src="../../assets/images/Group 8983@2x.png">
                </div>

                <div class="user-details">
                    <p>Hello</p>
                    <p>{{emailId}}</p>
                </div>
                <div class="terms-acceptance-area">
                    <p>
                        We've updated our <a style="font-weight: bold;"
                            (click)="openTermsWindow(policyOption)">{{policyOption}}.</a> Please read it
                        carefully.
                    </p>
                    <p>You <span style="font-weight: bold;">must agree </span>to the updated Terms in order to continue.
                    </p>
                    <p class="effective-date"> <span>{{policyOptionText}} Update</span> <span>Effective on
                            {{effectiveDate}}</span></p>
                </div>
                <div class="terms-cta">
                    <div (click)="updateTermsAndCondition()">I Agree</div>
                </div>
            </div>
        </div> -->
        <div class="footer" [ngClass] = "{'remove_footer_margin': !initialLoading}" [ngClass] = "{'addBlur': openTermsDialog || openUpdateTermsDialog}">
            <div class="globant-logo mobile">
                <img height="22px" width="113px" src="../../assets/space_theme/globant_logo_green/globant_logo_green@2x.png"
                    alt="globant_dark_logo" />
            </div>
            <div class="terms-social">
                <div class="terms-condition">
                    <p>All rights reserved Globant 2021.</p>
                    <p (click)="openTermsWindow('privacy policy')">Privacy Policy</p>
                    <p (click)="openTermsWindow('TERMS OF USE')">Terms of Use</p>
                    <p (click)="openTermsWindow('COOKIE')"> Cookie Policy</p>
                </div>
                <div class="social-handles">
                    <a href="https://www.facebook.com/Globant"><img src="../../assets/images/Facebook@2x.png"
                        alt="facebook" /></a>
                    <a href="https://twitter.com/globant"><img src="../../assets/images/Twitter@2x.png"
                            alt="twitter" /></a>
                    <a href="https://www.youtube.com/user/Globant"><img src="../../assets/images/Youtube@2x.png"
                            alt="youtube" /></a>
                    <a href="https://www.linkedin.com/company/globant"><img src="../../assets/images/LinkedIn@2x.png"
                            alt="linkedin" /></a>
                    <a href="https://www.instagram.com/globantpics"><img src="../../assets/images/Instagram@2x.png"
                            alt="instagram" /></a>
                    <a href="https://www.globant.com"><img src="../../assets/images/globant-vector-logo@2x.png"
                            alt="globant" /></a>
                </div>
            </div>
        </div>
        <!-- <div class="advertisment-mobile" [ngClass]="{'add-blur_mobile':openAdvertisment}">
            <div class="adver-mobile-wrppper" [ngClass]="{'add_wrapper_mobile_collapsed':!openAdvertisment}"
            [ngStyle]="{'visibility':initialLoading ? 'visible' : 'hidden' }"
                (click)="expandAdd()">
                <div class="adv-container-mobile" [ngStyle]="{'display':openAdvertisment ? 'block' : ''}">
                    <div class="close_btn_mobile_expanded" [ngStyle]="{'display':!openAdvertisment ? 'none' : '' }">
                        <span><img src="../../assets/space_theme/Close/Close@2x.png" width="31px" height="31px"></span>
                    </div>
                    <div class="adv-globantPods-logo">
                        <img class="globantpodslogo" src="../../assets/space_theme/Globant PODs Logo/Globant PODs Logo@2x.png" alt="globant pods logo"/>
                        <p class="sub-logo"> By <img class="by-globant-logo" src="../../assets/space_theme/globant-logo-dark/globant-logo-dark@2x.png" alt="globant dark logo" />
                    </div>
                    <div class="kudos-text-mobile">
                        <p> Send Kudos and Feedback to your PODs<br>
                            at any time. Access your feedback <br>
                            history and more in-depth knowledge of<br>
                            your PODs and members.</p>
                    </div>
                    <div class="download-links-mobile">
                        <a href="https://play.google.com/store/apps/details?id=com.globant.companionapp&hl=en_IN"
                            target="_blank"><img src="../../assets/images/Download app@2x.png" alt="android" /></a>
                        <a href="https://apps.apple.com/mr/app/globantpods/id1471234255" target="_blank"> <img
                                src="../../assets/images/Download  ios app@2x.png" alt="ios" /></a>
                    </div>
                    <div class="tilted-images">
                        <img src="../../assets/space_theme/mobile_phones/phones@2x.png" alt="facebook" />
                    </div>
                </div>
                <div class="close_btn_mobile_collpased" [ngStyle]="{'display':openAdvertisment ? 'none' : '' }">
                    <span>There's is an app for this, and is cooler!</span>
                    <span class="open-mobile-app-arrow"><img width="16px" height="16px" src="../../assets/space_theme/icons8-chevron-up-30.png" /></span>
                </div>
            </div>
        </div> -->
    </div>
    <div class="modal modal-gdpr fade show" style="padding-right: 15px; display: block;" *ngIf="openCookieBanner">
        <div class="lab-modal-body" [ngClass]="{'lab-modal-body-expand' : openCookiePrefrences}">
            <div class="banner">Please accept the Cookie Policy to view the website.</div>
            <div class="lab-body">
                <div class="eu-cookie-compliance-message col-sm-12 col-md-12 col-lg-12 col-xl-12" id="popup-text">
                    <h2>We Use Cookies</h2>
                    <p
                        [ngClass]="{'upDownAnimation' : openCookiePrefrences , 'upDownAnimation1' : cookieDeclarationPanel}">
                        We use cookies and other tracking technologies to improve your browsing experience on
                        our website, to show you personalized content and targeted ads, to analyze our website traffic,
                        and to understand where our visitors are coming from. By browsing our website, you consent to
                        our use of cookies and other tracking technologies.</p>
                    <a class="accept-button button cookie-accept-button" id="acceptCookiePolicy"
                        (click)="setCookies()">I
                        Understand</a>
                    <a id="cookie-details" class="cookie-details-open"
                        (click)="openCookiePrefrences = !openCookiePrefrences">Cookie Preferences
                        <span [ngStyle]="{'transform':openCookiePrefrences ? 'rotate(-180deg)' : '' }">▾</span>
                    </a>
                </div>

                <div class="testing" *ngIf="openCookiePrefrences">
                    <div class="border-div border-class"></div>
                    <div class="aa col-sm-12 col-md-12 col-lg-12 col-xl-12 cookie-preferences-layout"
                        style="display: block;">
                        <div class="cookies-checkboxes-wrapper row"></div>
                        <ul class="nav nav-tabs cookie-nav-tabs">
                            <div class="slider" [ngClass]="{'active_slider' : cookieDeclarationPanel}"></div>
                            <li class="active" (click)="cookieDeclarationPanel = false">
                                <a data-toggle="tab" [ngClass]="{'active' : !cookieDeclarationPanel}">PRIVACY POLICY
                                </a>
                            </li>
                            <li (click)="cookieDeclarationPanel = true">
                                <a data-toggle="tab" [ngClass]="{'active' :cookieDeclarationPanel}">COOKIE DECLARATION
                                </a>
                            </li>
                        </ul>
                        <div class="border-div border-class"></div>
                        <div class="tab-content cookie-tab-content">
                            <div class="tab-pane fade in cookie-tab-pane show"
                                [ngStyle]="{'display':!cookieDeclarationPanel ? 'block' : 'none' }">
                                <p [ngClass]="{'upDownAnimation' : openCookiePrefrences}">We use cookies to ensure that
                                    we give you the best experience on our website. If you
                                    continue without changing your settings, we'll assume that you are happy to receive
                                    all the cookies on GlobantPODs website. However, if you would like to, you can
                                    change your cookie settings at any time.</p>
                            </div>
                            <div class="tab-pane fade active show"
                                [ngStyle]="{'display':cookieDeclarationPanel ? 'block' : 'none' }">
                                <div class="row cookie-declaration-wrapper">
                                    <div class="tabs-column1">
                                        <div aria-orientation="vertical" class="nav flex-column nav-pills"
                                            id="v-pills-tab" role="tablist">
                                            <a aria-controls="v-pills-Necessary" aria-selected="true"
                                                class="cookie-nav-link active" data-toggle="pill"
                                                id="v-pills-Necessary-tab" role="tab">Necessary</a> </div>
                                    </div>
                                    <div class="tabs-column3">
                                        <div></div>
                                    </div>
                                    <div class="tabs-column2 cookie-tabs-column-content tabs-column-content">
                                        <div class="tab-content cookie-tab-content" id="v-pills-tabContent">
                                            <div aria-labelledby="v-pills--tab" class="tab-pane fade row active show"
                                                id="v-pills-Necessary" role="tabpanel">
                                                <div class="description col-12">
                                                    <div class="cookie-table-class">
                                                        <table
                                                            class="table table-bordered cookie-table-bordered table-condensed">
                                                            <thead>
                                                                <tr>
                                                                    <th>Cookie</th>
                                                                    <th>Category</th>
                                                                    <th>Purpose</th>
                                                                    <th>Expiration (if persistent)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td data-title="Cookie" class="td-data-title">
                                                                        __session</td>
                                                                    <td data-title="Category">Necessary</td>
                                                                    <td data-title="Purpose"> This cookie is used to
                                                                        store JWT token. The user is authenticated by
                                                                        this token.</td>
                                                                    <td data-title="Expiration (if persistent)">Session
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td data-title="Cookie" class="td-data-title">
                                                                        cookie-agreed</td>
                                                                    <td data-title="Category">Necessary</td>
                                                                    <td data-title="Purpose">Stores list of cookies for
                                                                        which user has provided the consent.</td>
                                                                    <td data-title="Expiration (if persistent)">1 year
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accept-updatedate-wrapper">
                                <div class="d-lg-block cookie-update-text">
                                    <p>Cookie declaration last updated on 30/12/2020 by<a> GlobantPods </a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>