import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { empty } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { HostListener } from '@angular/core';
import { environment } from '../../environments/environment';
import { message } from '../message.constant';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-form',
  templateUrl: './feedbackform.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  title = 'POD';
  expansion: boolean = false;
  @ViewChild('contentRef', { static: false }) contentRef: ElementRef;
  @ViewChild('contentRef', { static: false }) thankscontentRef: ElementRef;
  items: any = [];
  payload = [];
  options: string[] = ["Let's work on this", 'Cool', 'You Rock!']
  payloadOptions = {
    'You Rock!': "youRock",
    'Cool': "cool",
    "Let's work on this": "letsWorkOnThis"
  }
  currentId: number = null;
  openAdvertisment: boolean = false;
  isError: boolean = false;
  errorMsgHeading = message.SOMETHING_WENT_WRONG_HEADING;
  errorMsg: string = message.GENERIC_ERROR_MSG;
  errorCode: number = null;
  errorImg: string = message.SOMETHING_WENT_WRONG_IMG;
  isLoading: boolean = false;
  podDetails: any;
  podLogo: string = message.POD_DEFAULT_LOGO;
  podName: string = "";
  projectName: string = "";
  disableSummitBtn: Boolean = true;
  myInnerHeight: any = "";
  openTermsDialog: boolean = false;
  emailId: string = "";
  comments: string = "";
  webRefId: string = "";
  termsPageUrl: string = environment.termsPageUrl;
  privacyPolicyPageUrl: string = environment.privacyPolicyPageUrl;
  eid: string = "";
  initialLoading: boolean = false;
  openUpdateTermsDialog: boolean = false;
  policyOption: string = "TERMS OF USE";
  policyOptionText: string = "Terms of Use";
  effectiveDate: string = "";
  privacyTouStatus: number = 0;
  privacyTouUpdated: any;
  contactId: number;
  isCookie: boolean = false;
  openCookieBanner: boolean = false;
  openCookiePrefrences: boolean = false;
  cookieDeclarationPanel: boolean = false;
  tokenNotFound:boolean = false;
  optionImages = message.FEEDBACK_QESTION_OPTIONS;
  feedbackQuestions = {};
  isThankYouPage: boolean = false;
  feedbackStatus: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private authenticationService: AuthenticationService,
    public router: Router, private analytics: AngularFireAnalytics) { }

  ngOnInit() {
    this.verifyUser();
  }

  ngAfterViewInit() {
    if (!this.feedbackStatus)
    //this.thankscontentRef.nativeElement.innerHTML = message.ALREADY_SENT_FEEDBACK_MSG;
    this.analytics.setCurrentScreen('Feedback Successful');
    this.analytics.logEvent('screen_view')
  }

  async setCookies() {
    this.openCookieBanner = false;
    this.isLoading = true;
    await this.authenticationService.setCookies(window.localStorage.getItem('idToken'));
    setTimeout(() => {
      this.getPodDetails();
    }, 2000)
  }

  async verifyUser() {
    this.isLoading = true;
    let token;
      this.activatedRoute.queryParamMap.subscribe((queryParmas: any) => {
        if(queryParmas.params.refId && queryParmas.params.eId) {
          token = queryParmas.params.refId || " ";
          this.eid = queryParmas.params.eId;
          this.webRefId = token;
        } else {
          this.tokenNotFound = true;
        }
      });
      if(this.tokenNotFound) {
        this.router.navigate(['/form']);
      } else {
        try {
          let result: any = await this.authenticationService.fetchCustomToken(token, this.eid);
          if (result.errorCode == 0) {
            this.authenticationService.setPodDetails(result);
            this.emailId = result.data.email;
            this.isCookie = result.data.isCookie;
            this.authenticationService.authenticateUser(result.data.token).then((result1) => {
              var user = result1.user;
              this.analytics.logEvent('Login', { UserId: this.emailId });
              user.getIdToken().then((idToken) => {
                localStorage.setItem("idToken", idToken);
                if (this.isCookie) {
                  this.setCookies();
                } else {
                  this.openCookieBanner = true;
                  this.isLoading = false;
                }
              });
            }).catch((error) => {
              console.log(error);
              this.isLoading = false;
              this.setScreenTrackingEvents('Bummer');
            });
          } else {
            if(result.errorCode == 4016) {
              this.podName = result['data']['podName'];
              this.podLogo = result['data']['podLogo']+"&web=true";
              this.projectName = result['data']['accountName'];
              //this.thankscontentRef.nativeElement.innerHTML = message.ALREADY_SENT_FEEDBACK_MSG;
              this.analytics.setCurrentScreen('Feedback Successful');
              this.analytics.logEvent('screen_view')
            }
            this.errorHandling(result);
          }
        }
        catch (error) {
          this.isLoading = false;
          this.isError = true;
          this.openTermsDialog = false;
          this.errorMsg = message.GENERIC_ERROR_MSG;
          this.errorCode = error.error.errorCode;
          this.setScreenTrackingEvents('Bummer');
        }
      }
  }

  getPodDetails() {
    this.authenticationService.fetchPodDetails(this.webRefId).then((resp: any) => {
      if (resp.errorCode == 0 && resp.data.privacyTouStatus == 0) {
        this.initialLoading = true;
        this.openAdvertisment = true;
        this.setScreenTrackingEvents('Feedback Area List');
        setTimeout(() => {
          this.analytics.logEvent('FeedBackForm', { UserId: this.emailId });
          this.podDetails = resp.data.pods[0];
          this.termsPageUrl = resp.data.privacyTouAccepted.touUrl + "&web=true";
          this.privacyPolicyPageUrl = resp.data.privacyTouAccepted.privacyUrl + "&web=true";
          if (this.podDetails.isMember) {
            this.items = this.podDetails.areas;
            // if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && this.items.length > 7) {
            //   this.myInnerHeight = (this.items.length - 7) * 70 + 1800;
            // }
            this.payload = new Array(this.items.length).fill(null);
            this.podName = this.podDetails.podName;
            this.podLogo = this.podDetails.podLogo + "&web=true"
            this.projectName = /*this.podDetails.projectName + " • " + */ resp.data.loggedInUserAccount;
            this.getQuestionDetails();
            //this.isLoading = false;
          } else {
            this.isLoading = false;
            this.isError = true;
            this.errorMsgHeading = message.BUMMER_HEADING;
            this.errorImg = message.BUMMER_IMG_PATH;
            this.errorMsg = message.ZERO_MEMBER_ERROR;
            this.errorCode = 500;
          }
        }, 500);
        setTimeout(() => {
          this.openAdvertisment = false;
        }, 3500);
      } else if (resp.errorCode == 0 && resp.data.privacyTouStatus > 0) {
        this.updateTermsHandling(resp.data.privacyTouStatus, resp.data.privacyTouUpdated);
        this.contactId = resp.data.loggedInContactId;
      } else {
        this.errorHandling(resp)
      }
    }).catch((err) => {
      console.log(err);
      this.errorCode = 500
      this.isLoading = false;
      this.isError = true;
      this.setScreenTrackingEvents('Bummer');
    })
  }

  updateTermsHandling(privacyTouStatus, privacyTouUpdated) {
    this.isLoading = false;
    this.privacyTouStatus = privacyTouStatus;
    this.privacyTouUpdated = privacyTouUpdated;
    if (privacyTouStatus == 2 || privacyTouStatus == 3) {
      this.openUpdateTermsDialog = true;
      this.policyOption = "TERMS OF USE";
      this.termsPageUrl = privacyTouUpdated.touUrl;
      this.formatDate(privacyTouUpdated.touDate._seconds);
    } else if (privacyTouStatus == 1) {
      this.openUpdateTermsDialog = true;
      this.policyOption = "PRIVACY POLICY";
      this.policyOptionText = "Privacy Policy";
      this.privacyPolicyPageUrl = privacyTouUpdated.privacyUrl;
      this.formatDate(privacyTouUpdated.privacyDate._seconds);
    }
  }

  formatDate(date) {
    let tempDate = new Date(date * 1000)
    let month = tempDate.toLocaleString('default', { month: 'long' });
    let day = tempDate.getDate();
    let year = tempDate.getFullYear();
    this.effectiveDate = `${month} ${day}, ${year}`;
  }

  errorHandling(result) {
    if (result.errorCode == 4014) {
      this.isError = true;
      this.errorMsgHeading = message.BUMMER_HEADING;
      this.errorImg = message.BUMMER_IMG_PATH;
      this.errorMsg = message.CLIENT_DELETED_ERROR_MSG;
      this.errorCode = result.errorCode;
      this.isLoading = false;
    } else if (result.errorCode == 4016) {
      this.isLoading = false;
      this.authenticationService.setPodDetails(result)
      //this.router.navigate(['/thanks']);
      this.isThankYouPage = true;
      this.feedbackStatus = false;
    } else if (result.errorCode == 4017) {
      // this.emailId = result.data.loggedInUserEmail;
      this.openTermsDialog = true;
      this.isLoading = false;
    } else if (result.errorCode == 4045) {
      this.isError = true;
      this.errorMsg = message.POD_DELETED_ERROR_MSG;
      this.errorMsgHeading = message.BUMMER_HEADING;
      this.errorImg = message.BUMMER_IMG_PATH;
      this.errorCode = result.errorCode;
      this.isLoading = false;
      this.openTermsDialog = false;
    } else if (result.errorCode == 4046) {
      this.isError = true;
      this.errorCode = result.errorCode;
      this.errorMsgHeading = message.BUMMER_HEADING;
      this.errorImg = message.BUMMER_IMG_PATH;
      this.errorMsg = message.ZERO_MEMBER_ERROR;
      this.isLoading = false;
    } else if (result.errorCode == 4018) {
      this.isError = true;
      this.errorCode = result.errorCode;
      this.errorMsgHeading = message.BUMMER_HEADING;
      this.errorImg = message.BUMMER_IMG_PATH;
      this.errorMsg = message.READ_ONLY_USER_ERROR;
      this.isLoading = false;
    } else if (!navigator.onLine) {
      this.isError = true;
      this.errorMsgHeading = message.NO_INTERNET_HEADING;
      this.errorMsg = message.NO_INTERNET_MSG;
      this.isLoading = false;
    } else {
      this.isError = true;
      this.errorMsg = message.GENERIC_ERROR_MSG;
      this.isLoading = false;
    }

    this.setScreenTrackingEvents('Bummer');
  }

  toggle(id: number) {
    this.expansion = !this.expansion;
    this.currentId = id;
    this.payload[id] = null;
    let temp = this.payload.filter(value => this.options.includes(value));
    this.disableSummitBtn = !(temp.length > 0);
  }

  // selectionHandler(i: number, option: string, item: string) {
  //   this.payload[i] = option
  //   console.log(this.payload)
  //   this.expansion = !this.expansion;
  //   let temp = this.payload.filter(value => this.options.includes(value));
  //   this.disableSummitBtn = !(temp.length > 0);
  // }

  expandAdd() {
    this.openAdvertisment = !this.openAdvertisment;
  }

  animEnd(evt) {
    if (this.contentRef) {
      if (this.openAdvertisment)
        this.contentRef.nativeElement.style.zIndex = 0;
      else
        this.contentRef.nativeElement.style.zIndex = 2;
    }
  }

  sumbitFeedback() {
    this.isLoading = true;
    window.scroll(0, 500);
    let payload = {};
    payload['clientId'] = this.podDetails.clientId;
    payload['comment'] = this.comments;
    payload['platform'] = "WEB";
    payload['podId'] = this.podDetails.podId
    payload['questionAnswers'] = this.payload;
    payload['requestId'] = Number(this.webRefId);
    this.authenticationService.submitFeedback(payload, this.webRefId).then((resp: any) => {
      if (resp.success) {
        //this.router.navigate(['/thanks']);
        this.isThankYouPage = true;
        this.feedbackStatus = true;
        this.analytics.logEvent('SentFeedback', { UserId: this.emailId });
        this.analytics.logEvent('SentFeedback_Requested', { UserId: this.emailId });
        // this.setScreenTrackingEvents('Feedback Successful');
      } else {
        if (resp.errorCode == 4013) {
          this.getRefreshTokenAndtryAgain()
        } else {
          this.errorHandling(resp)
        }
      }
      this.isLoading = false;
    }).catch((err) => {
      if (err.error.errorCode == 4013) {
        this.getRefreshTokenAndtryAgain()
      } else {
        this.errorHandling(err.error)
      }
      this.isLoading = false
    })
  }

  getRefreshTokenAndtryAgain() {
    this.isLoading = true;
    this.authenticationService.refreshToken().getIdToken(true).then((refreshToken) => {
      console.log('refresh Token', refreshToken);
      localStorage.setItem("idToken", refreshToken);
      this.sumbitFeedback();
    })
  }

  tryAgain() {
    if (this.errorCode == 4014 || this.errorCode == 500) {
      this.isError = true;
    } else {
      this.isError = false;
    }
    this.isError = (this.errorCode == 4014 || this.errorCode == 500);
    window.location.reload();
  }

  openTermsWindow(type) {
    if (type === 'TERMS OF USE')
      window.open(this.termsPageUrl);
    else if (type === 'COOKIE')
      window.open(environment.cookiePolicyPageUrl);
    else
      window.open(this.privacyPolicyPageUrl);
  }

  acceptTermsAndCondition() {
    this.isLoading = true;
    this.authenticationService.updateAcceptanceDateOfTermsAndPrivacy(this.eid, this.webRefId).then((resp: any) => {
      if (resp.errorCode == 0) {
        this.getPodDetails();
        this.openTermsDialog = false;
      }
    }).catch((err) => {
      this.isLoading = false;
      this.isError = true;
      this.setScreenTrackingEvents('Bummer');
    })
  }

  updateTermsAndCondition() {
    this.isLoading = true;
    if (this.privacyTouStatus == 1 || this.privacyTouStatus == 2) {
      let version = (this.privacyTouStatus == 2) ? this.privacyTouUpdated.touVersion : this.privacyTouUpdated.privacyVersion;
      this.authenticationService.updateTermsAndPrivcy(this.privacyTouStatus, version, this.contactId).then((resp: any) => {
        if (resp.errorCode == 0) {
          this.getPodDetails();
          this.openUpdateTermsDialog = false;
        }
      }).catch((err) => {
        this.isLoading = false;
      })
    } else if (this.privacyTouStatus == 3) {
      this.privacyTouStatus = 1;
      this.updateTermsHandling(this.privacyTouStatus, this.privacyTouUpdated);
      this.authenticationService.updateTermsAndPrivcy(2, this.privacyTouUpdated.touVersion, this.contactId).then((resp: any) => { });
    }
  }

  setScreenTrackingEvents(currentScreen) {
    this.analytics.setCurrentScreen(currentScreen);
    this.analytics.logEvent('screen_view')
  }

  getQuestionDetails() {
    this.isLoading = true;
    this.authenticationService.fetchQuestionDetails(this.webRefId).then((resp: any) => {
       this.feedbackQuestions = resp['data'];
       this.isLoading = false;
    }).catch((err) => {
      console.log(err);
      this.errorCode = 500
      this.isLoading = false;
      this.isError = true;
      this.setScreenTrackingEvents('Bummer');
    })
  }

  optionSelected(questionId, selectedOption) {
    const question = this.feedbackQuestions['questions'].filter(question => question.questionId == questionId);
    question[0]['options'].forEach(option => {
        if (option['optionId'] == selectedOption['optionId']) {
            option['selected'] = true;
        } else {
            option['selected'] = false;
        }
    });
    this.updateSelectedAnswers();
  }

  public updateSelectedAnswers(){
    this.payload = [];
    this.feedbackQuestions['questions'].map(question =>{
        question['options'].map(option => {
            if(option.selected == true){
                this.payload.push({'questionId':question.questionId, 'answerId':option.optionId});
            }              
        });
    });
    this.disableSummitBtn = ((this.feedbackQuestions['questions'] || []).length == this.payload.length) ? false : true;
  }
}
