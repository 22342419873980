import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailComponent } from './email/email.component';
import { FormComponent } from './form/form.component';
import { ThanksComponent } from './thanks/thanks.component';
import { AuthGaurdService } from './auth-gaurd.service';
import { SendFeedbackComponent } from './send-feedback/send-feedback.component';

const routes: Routes = [
  {
    'path': 'email',
    'component': EmailComponent
  },
  {
    'path': 'thanks',
    'component': ThanksComponent,
    canActivate: [AuthGaurdService]
  },
  {
    'path': 'form',
    'component': FormComponent
  },
  {
    'path': '',
    'component': FormComponent
  },
  {
    path: 'sendFeedback',
    component: SendFeedbackComponent
  },
  {
    path: '**',
    component: SendFeedbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
