import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService implements CanActivate {

  constructor(private authenticationService: AuthenticationService , public router : Router) { }

  canActivate() {
    if (this.authenticationService.getPodDetails())
      return true
    else {
      this.router.navigate(['/form'])
      return false;
    }
  }
}
