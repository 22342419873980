<div>
	<div class="wrapper">
	    <div class="green-shape">
		<div class="pod-logo">
		    <img src="../../assets/space_theme/Globant PODs Logo/Globant PODs Logo@2x.png" alt="globant_pod_logo" />
		</div>
	    </div>
	    <div class="content">
		<div class="inner-content">
		    <div class="innner-content-left">
			<div class="thanks-note">
			    <div>
				<p class="hmm-text">Want to send feedback?</p>
			    </div>
			    <div>
				<p>If you want to check your past feedback or send another one, you can always use the GlobantPODs app.</p>
			    </div>
			    <div>
				<p>Download the app </p>
				<img src="../../assets/images/Group 8902@2x.png" alt="download_arrow" />
			    </div>
			    <div class="download mobile">
				<div>
				    <a href="https://play.google.com/store/apps/details?id=com.globant.companionapp&hl=en_IN"
					target="_blank"> <img
					    src="../../assets/space_theme/Download_app/Download_app@2x.png" alt="google_Store" /> </a>
	
				    <a href="https://apps.apple.com/mr/app/globantpods/id1471234255" target="_blank">
					<img  src="../../assets/space_theme/Download_ios/Download_ios@2x.png"
					    alt="apple_store" />
				    </a>
				</div>
			    </div>
			</div>
		    </div>
		    <div class="innner-content-right">
			<div class="tilted-images">
			    <img src="../../assets/space_theme/phones/phones2x_cropped.png" alt="adv phones" />
			</div>
			<!-- primary right content -->
			<div class="gobant-logo-large">
			    <img src="../../assets/space_theme/Globant PODs Logo/Globant PODs Logo@2x.png" alt="globant pods logo"/>
			</div>
			<div class="kudos-text">
			    <p> Send Kudos and Feedback to your PODs at <br>
				any time. Access your feedback history and <br>
				more in-deph knowledge of your PODs and<br>
				members.</p>
			</div>
			<div class="download">
			    <div>
				<a href="https://play.google.com/store/apps/details?id=com.globant.companionapp&hl=en_IN"
				    target="_blank"> <img
					src="../../assets/space_theme/Download_app/Download_app@2x.png" alt="google_Store" /> </a>
    
				<a href="https://apps.apple.com/mr/app/globantpods/id1471234255" target="_blank">
				    <img  src="../../assets/space_theme/Download_ios/Download_ios@2x.png"
					alt="apple_store" />
				</a>
			    </div>
			</div>
		    </div>
		</div>
	    </div>
	    <div class="footer">
		<div class="globant-logo mobile">
		    <img height="22px" width="113px" src="../../assets/space_theme/globant_logo_green/globant_logo_green@2x.png"
			alt="globant_dark_logo" />
		</div>
		<div class="terms-social">
		    <div class="terms-condition">
			<p>All rights reserved Globant 2021.</p>
			<p (click)="openTermsWindow('privacy policy')">Privacy Policy</p>
			<p (click)="openTermsWindow('TERMS OF USE')">Terms of Use</p>
			<p (click)="openTermsWindow('COOKIE')"> Cookie Policy</p>
		    </div>
		    <div class="social-handles">
			<a href="https://www.facebook.com/Globant"><img src="../../assets/space_theme/Facebook/Facebook@2x.png"
			    alt="facebook" /></a>
			<a href="https://twitter.com/globant"><img src="../../assets/space_theme/Twitter/Twitter@2x.png"
				alt="twitter" /></a>
			<a href="https://www.youtube.com/user/Globant"><img src="../../assets/space_theme/Youtube/Youtube@2x.png"
				alt="youtube" /></a>
			<a href="https://www.linkedin.com/company/globant"><img src="../../assets/space_theme/LinkedIn/LinkedIn@2x.png"
				alt="linkedin" /></a>
			<a href="https://www.instagram.com/globantpics"><img src="../../assets/space_theme/Instagram/Instagram@2x.png"
				alt="instagram" /></a>
			<a href="https://www.globant.com"><img src="../../assets/space_theme/globant-vector-logo/globant-vector-logo@2x.png"
				alt="globant" /></a>
		    </div>
		</div>
	    </div>
	</div>
    </div>