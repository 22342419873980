export const message = {
  POD_DEFAULT_LOGO: "../../assets/images/animals-symbols-01@2x.png",

  SOMETHING_WENT_WRONG_HEADING: "Oops! Something went wrong",
  SOMETHING_WENT_WRONG_ERROR: "Oops! Something went wrong.",
  SOMETHING_WENT_WRONG_IMG: "../../assets/space_theme/planet_astronaut/planet_astronaut@2x.png",

  GENERIC_ERROR_MSG: "This is awkward, for some reason our spaceship has broken.",
  NO_INTERNET_HEADING: "No Internet connection",
  NO_INTERNET_MSG: "You need an Internet connection for the magic to happen.",

  BUMMER_HEADING: "Bummer!",
  BUMMER_IMG_PATH: "../../assets/space_theme/plante-accident/planet.png",
  ZERO_MEMBER_ERROR: "You can't send feedback to this POD because there are no members assigned. Please contact your GLOBANT PARTNER.",
  POD_DELETED_ERROR_MSG: "It seems this POD is no longer assigned to you or has been deleted. Please contact your GLOBANT PARTNER.",
  CLIENT_DELETED_ERROR_MSG: "It seems you are no longer registered as a contact of the account. Please contact your GLOBANT PARTNER.",

  ALREADY_SENT_FEEDBACK_MSG: "Seems like you already answered this feedback request, Thank You.",

  READ_ONLY_USER_ERROR: "You don't have permission to send feedback. Please contact your GLOBANT PARTNER.",
  FEEDBACK_QESTION_OPTIONS : {
    'Strongly Disagree' : 'strongly-disagree',
    'Disagree' : 'disagree',
    'Neither Agree or Disagree' : 'neither-agree-disagree',
    'Agree' : 'agree',
    'Strongly Agree' : 'totally-agree'
  }
};