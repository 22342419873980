<div class="wrapper">
  <div class="dummy"></div>
  <div class="dummy-height"></div>
  <div class="sub-wrapper">
    <div class="media-container">
      <div class="container">
        <div class="header">
          <div class="green-shape">
            <div>We are</div>
            <div>Listening</div>
          </div>
          <div class="logo">
            <img src="../assets/images/globant-logo-dark.png" />
          </div>
        </div>
        <div class="dummy-feedback-container"></div>
        <div class="feedback-container">
          <div class="feedback-salutation">
            <p>Hi Merly,</p>
          </div>
          <div class="feedback-content">
            <div>
              <p>We would appreciate, if you could spare a few minutes and <span class="special">provide us</span></p>
              <p>Feedback for <span>Foxy Rangers POD</span> </p>
            </div>
            <div>
              <p><span>Why Feedback ?</span></p>
              <p>Help us improve and grow in our areas of work.</p>
            </div>
            <div>
              <p><span>Help us to Serve you better!</span></p>
            </div>
          </div>
        </div>
        <div class="dummy-feedback-btn"></div>
        <div class="feedback-btn">
          <a href="http://www.google.com"><span>Let's share some love</span></a>
          <p>Thank you for your time!</p>
        </div>
      </div>
    </div>
    <div class="link-conatiner">
      <div class="social-handles">
        <a href=" https://www.facebook.com/Globant"><img src="../assets/images/Facebook@2x.png" alt="facebook" /></a>
        <a href="https://twitter.com/globant"><img src="../assets/images/Twitter@2x.png" alt="twitter" /></a>
        <a href="https://www.youtube.com/user/Globant"><img src="../assets/images/Youtube@2x.png" alt="youtube" /></a>
        <a href="https://www.linkedin.com/company/globant"><img src="../assets/images/LinkedIn@2x.png" alt="linkedin" /></a>
        <a href=" https://www.instagram.com/globantpics/"><img src="../assets/images/Instagram@2x.png" alt="instagram" /></a>
        <a href="https://www.globant.com/"><img src="../assets/images/globant-vector-logo@2x.png" alt="globant" /></a>
      </div>
      <div class="copyright">
        <p>(c) 2020 Globant. All rights reserved </p>
      </div>
    </div>
  </div>
</div>