import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { environment } from '../../environments/environment';
import { message } from '../message.constant';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent {
  @ViewChild('contentRef', { static: false }) contentRef: ElementRef;
  title = 'POD';
  podDetails: any;
  podLogo: string = message.POD_DEFAULT_LOGO;
  podName: string = "";
  projectName: string = "";
  feedbackStatus: boolean = true;

  constructor(private authenticationService: AuthenticationService, private analytics: AngularFireAnalytics) { }

  ngOnInit() {
    let resp = this.authenticationService.getPodDetails();
    this.podDetails = resp.data;
    this.podName = this.podDetails.podName;
    this.podLogo = this.podDetails.podLogo + "&web=true";
    this.projectName =  /*this.podDetails.projectName + " • " +*/ this.podDetails.accountName;
    if (resp && resp.errorCode && resp.errorCode == 4016) {
      this.feedbackStatus = false;
    }
  }

  ngAfterViewInit() {
    if (!this.feedbackStatus)
      this.contentRef.nativeElement.innerHTML = message.ALREADY_SENT_FEEDBACK_MSG;
    this.analytics.setCurrentScreen('Feedback Successful');
    this.analytics.logEvent('screen_view')
  }

  openTermsWindow(type) {
    if (type == 'TERMS OF USE')
      window.open(environment.termsPageUrl);
    else if (type === 'COOKIE')
      window.open(environment.cookiePolicyPageUrl);
    else
      window.open(environment.privacyPolicyPageUrl);
  }
}
