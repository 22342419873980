import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmailComponent } from './email/email.component';
import { FormComponent } from './form/form.component';
import { ThanksComponent } from './thanks/thanks.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, CONFIG, DEBUG_MODE } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { SendFeedbackComponent } from './send-feedback/send-feedback.component';


const firebaseConfig = environment.firebaseConfig;

@NgModule({
  declarations: [
    AppComponent,
    EmailComponent,
    FormComponent,
    ThanksComponent,
    SendFeedbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    ProgressSpinnerModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    { provide: DEBUG_MODE, useValue: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
